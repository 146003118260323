<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <div class="d-flex align-center">
          <v-icon x-large>group</v-icon>
          <h1 class="mx-3">הזמנות</h1>
          <span style="font-size: 15px">{{ customer.name }}</span>
        </div>
        <v-spacer></v-spacer>

        <div>
          <a :href="`tel:${customer.phone}`">{{ customer.phone }}</a>
          <v-btn
            small
            icon
            target="_blank"
            class="ms-3"
            :href="`https://wa.me/${customer.phone}`"
          >
            <img width="30" src="@/assets/whatsapp.png" />
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :mobile-breakpoint="0"
          :hide-default-footer="true"
          :disable-pagination="true"
          :items="items"
          :headers="headers"
        >
          <template v-slot:[`item.customer`]="{ item }">
            {{ item.customer.name }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              class="px-2"
              x-small
              color="warning"
              v-if="item.status == 'pending'"
            >
              בהמתנה
            </v-chip>
            <v-chip
              class="px-2"
              x-small
              color="grey lighten-2"
              v-if="item.status == 'new'"
            >
              חדש
            </v-chip>
            <v-chip
              class="px-2"
              x-small
              color="warning lighten-2"
              v-else-if="item.status == 'preparation'"
            >
              בהכנה
            </v-chip>

            <v-chip
              class="px-2"
              x-small
              color="pink success"
              v-else-if="item.status == 'ready'"
            >
              מוכן
            </v-chip>

            <v-chip
              class="px-2"
              x-small
              color="blue lighten-2 white--text"
              v-else-if="item.status == 'in_progress_delivery'"
            >
              בדרך
            </v-chip>
            <v-chip
              class="px-2"
              x-small
              color="priamry lighten-2"
              v-else-if="item.status == 'delivery_arrived'"
            >
              הגיע השליח
            </v-chip>

            <v-chip
              class="px-2"
              x-small
              color="success lighten-2"
              v-else-if="item.status == 'done'"
            >
              טופל
            </v-chip>

            <v-chip
              class="px-2"
              x-small
              color="red lighten-2 white--text"
              v-else-if="item.status == 'canceled'"
            >
              מבוטלת
            </v-chip>
          </template>
          <template v-slot:[`item.delivery`]="{ item }">
            <span v-if="item.delivery">
              {{ item.delivery.name }}
            </span>
            <span v-else> __ </span>
          </template>
          <!--/ actions -->
        </v-data-table>
      </v-card-text>
    </v-card>

    <template>
      <div
        style="margin-bottom: 100px"
        v-if="data_loaded"
        class="text-center mt-4 d-flex justify-center"
      >
        <v-pagination
          @input="get_data()"
          v-model="page"
          total-visible="7"
          :length="total"
          circle
        ></v-pagination>
      </div>
    </template>

    <v-snackbar color="#333" left v-model="snackbar_success">
      <p class="white--text font-weight-bold">עודכן בהצלחה</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: [],
  data() {
    return {
      snackbar_success: false,
      data_loaded: false,
      headers: [
        {
          value: "created_at",
          text: "תאריך",
          align: "center",
        },
        {
          value: "order_num",
          text: "מספר הזמנה",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס הזמנה",
          align: "center",
        },
        {
          value: "payment_method",
          text: "סוג תשלום",
          align: "center",
        },
        {
          value: "customer",
          text: "לקוח",
          align: "center",
        },
        {
          value: "delivery",
          text: "שליח",
          align: "center",
        },
        {
          value: "delivery_cost",
          text: "עלות משלוח",
          align: "center",
        },
        {
          value: "total",
          text: 'סה"כ',
          align: "center",
        },
      ],
      customer: {
        name: null,
      },
      items: [],
      total: 0,
      page: 1,
    };
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `/customer_orders/${this.$route.params.id}?page=${this.page}`,
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data.data;
          this.items = Object.assign([], data.orders.data);
          this.total = data.orders.meta.last_page;
          this.page = data.orders.meta.current_page;

          this.customer = data.customer;
          this.data_loaded = true;
        });
    },

    save(item) {},
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss" scoped>
.basic {
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
